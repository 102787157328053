import { isEmbeddedRoute } from "~/utils/route_helpers";

export class Resizer {
  private static instance: Resizer;
  private listenersInitialized;

  private constructor() {
    this.listenersInitialized = false;
  }

  static getInstance(): Resizer {
    if (!Resizer.instance) {
      Resizer.instance = new Resizer();
    }
    return Resizer.instance;
  }

  initListeners() {
    if (this.listenersInitialized || !this.embedded()) return;

    window.addEventListener("message", this.onMessage);

    this.listenersInitialized = true;
  }

  handleResize() {
    if (!this.embedded()) return;
    this.postHeight(document.body.scrollHeight);
  }

  private embedded() {
    return isEmbeddedRoute(window?.location.pathname || "");
  }

  private onMessage = (e: MessageEvent) => {
    if (e.data == "resize") {
      this.handleResize();
    }
  };

  private postHeight = (height: number) => {
    window.parent.postMessage(height, "*");
  };
}
